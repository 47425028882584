import ld from "lodash";

import { defineStore } from "pinia";
import type StoreDto from "~/models/StoreDto";
import AccessGroupCustomerEnum from "~/models/AccessGroupCustomerEnum";
import StoreDetailDto from "~/models/StoreDetailDto";
import type ActiveStoreDto from "~/models/ActiveStoreDto";
import { storeIconUpload } from "~/utils/UploadImageService";
import type CreateStoreDto from "~/models/CreateStoreDto";
import { VendorCustomerProvider } from "~/provider/VendorCustomerProvider";
import CreateStoreDetailDto from "~/models/CreateStoreDetailDto";
import type { AnybillResult } from "~/additionalModels/AnybillResponse";

// TODO Rework this with provider logic
export const useStoreModule = defineStore("store", () => {
  const userModule = useUserModule();
  const stores = ref<StoreDto[] | null>(null);
  const activeStores = ref<ActiveStoreDto[] | null>(null);
  const unavailable = ref<boolean | null>(null);
  const loading = ref(false);
  // indicates wheter populateAll is running or not
  const allLoading = ref(false);
  const totalStoreCount = ref(0);

  // local stores store-dialog
  const currentStore = ref<StoreDto | null>(null);
  const storeCreationLogo = ref<File | null>(null);
  const isEdit = ref(false);
  const initialized = computed(() => {
    return !!stores.value && !!activeStores.value;
  });

  const hasStores = computed(() => {
    if (!stores.value)
      return false;
    return stores!.value.length > 0;
  });

  const _hasReadrights: any = computed<boolean>(() => {
    return (userModule.userRights?.get(AccessGroupCustomerEnum.Store) ?? 0) >= 1;
  });

  function setSortedStores(partnerRestriction: any) {
    const notSelected = stores.value?.filter(s => !partnerRestriction?.storeIds?.includes(s.id));
    const selected = stores.value?.filter(s => partnerRestriction?.storeIds?.includes(s.id));
    if (selected?.length === 0)
      stores.value?.sort((a: any, b) => a.storeDetail.displayName.localeCompare(b.storeDetail.displayName));
    // this.SET_STORES(this.stores);

    if (selected && selected?.length !== 0 && notSelected && notSelected?.length !== 0) {
      selected?.sort((a: any, b) => a.storeDetail.displayName.localeCompare(b.storeDetail.displayName));
      notSelected?.sort((a: any, b) => a.storeDetail.displayName.localeCompare(b.storeDetail.displayName));
      stores.value = [...selected, ...notSelected];
    }
  }

  function reset() {
    stores.value = null;
    unavailable.value = false;
  }

  async function populate({ skip = 0, preserve = false }: { skip?: number; preserve?: boolean } = { skip: 0, preserve: false }) {
    if (stores.value?.length === totalStoreCount.value)
      return; // leave early if already populated by populateAll

    loading.value = true;
    let _stores: StoreDto[] = [];
    let _totalCount: number = 0;
    if (!_hasReadrights.value) {
      unavailable.value = true;
      return;
    }

    // get stores
    // const getAllInfo = await new StoreService().getAll({ skip, take: 800 });

    const storeRes = await useTypedFetch<AnybillResult<StoreDto[]>>("/storeService/get?skip=0&take=400");

    if (storeRes.success) {
      if (storeRes.statusCode !== 204)
        _stores = storeRes.value;

      _totalCount = storeRes.value.length;
      _stores.sort((a: StoreDto, b: StoreDto) => {
        return ((a.storeDetail.displayName as string).localeCompare(b.storeDetail.displayName as string)) as number;
      });
    }
    else {
      AnybillLogger.instance.error("Something went wrong in  store get");
    }

    // get active stores
    // const activeStores = await new StoreActivestoresService().get(`${skip}`, "100");
    const activeStoresRes = await useTypedFetch<AnybillResult<ActiveStoreDto[]>>(`/storeActivestoresService/get?skip=${skip}&take=100`);
    if (activeStoresRes.success)
      activeStores.value = activeStoresRes.value;

    // Todo: process setSelectedIds in BillStatisticsModule
    if (!preserve) {
      totalStoreCount.value = _totalCount;
      stores.value = _stores;
    }
    else {
      _summarizeStores(_stores);
    }
    loading.value = false;
    unavailable.value = false;
  }
  const skip = ref(0);

  // called by content areas
  // get all store at once?
  async function populateAll() {
    allLoading.value = true;
    skip.value = stores.value!.length!;
    // const getAllInfo = await new StoreService().getAll({ skip, take: 300 });
    const getAllInfo: any = await useTypedFetch(`/storeService/get`);
    _summarizeStores(getAllInfo.elements);

    skip.value += getAllInfo.value.length;
    while (skip.value < totalStoreCount.value) {
      // const getAllInfo = await new StoreService().getAll({ skip, take: 300 });
      const getAllInfo: any = await useTypedFetch(`/storeService/get?skip=${skip.value}&take=300`);
      _summarizeStores(getAllInfo.elements);
      skip.value += getAllInfo.elements.length;
    }

    allLoading.value = false;
  }

  async function createStore(createStoreDto: CreateStoreDto, vendorCustomerId: string | undefined, image: File | null) {
    let storeId;
    let res;
    if (vendorCustomerId) {
      res = await VendorCustomerProvider.instance.createStore(vendorCustomerId, createStoreDto);

      if (res.success) {
        const storeId = res.value.id;
        const object = await VendorCustomerProvider.instance.getSingleStore(vendorCustomerId, storeId);

        if (object.success) {
          _summarizeStores([object.value]);
          totalStoreCount.value++;
        }
        storeCreationLogo.value = null;
        currentStore.value = null;
      }
    }
    else {
      res = await useTypedFetch<AnybillResult<StoreDto>>("/storeService/post", createStoreDto);

      if (res.success) {
        storeId = res.value.id;

        // add companylogo
        if (!!image && !vendorCustomerId)
          await _uploadCompanyLogo(storeId, image);

        // update storelist
        const single = await useTypedFetch<AnybillResult<StoreDto>>("/storeSpecificService/get", { storeId });
        if (single.success)
          _summarizeStores([single.value]);

        totalStoreCount.value++;
      }
    }
  }

  /**
   * add store data
   * If id is set cusstomer is activated
   */
  async function addStore({ id, image }: { id: string | undefined | null; image: File | null }) {
    let storeId = "";
    let res: any;
    // create store
    if (id) {
      res = await useTypedFetch<AnybillResult<StoreDto>>("/vendorcustomerSpecificStoreService/post", {
        body: currentStore.value,
        vendorCustomerId: id,
      });
    }
    else {
      res = await useTypedFetch<AnybillResult<StoreDto>>("/storeService/post", currentStore.value);
    }
    if (res.success)
      storeId = res!.value.id;
    else
      AnybillLogger.instance.error("Something went wrong in post store (addstore)");

    // add companylogo
    if (!!image && !id)
      await _uploadCompanyLogo(storeId, image);

    // get updatet store list
    let single: any;
    if (id) {
      single = await useTypedFetch<AnybillResult<StoreDto>>("/vendorcustomerSpecificStoreSpecificService/get", {
        vendorCustomerId: id,
        storeId,
      });
    }
    else {
      single = await useTypedFetch<AnybillResult<StoreDto>>("/storeSpecificService/get", storeId);
    }

    if (single.success) {
      stores.value!.push(res.value);
      _summarizeStores(single.value);
      totalStoreCount.value++;
    }
    else {
      AnybillLogger.instance.error("Something went wrong in store.ts get single store (addStore)");
    }
  }

  /**
   * update store data
   * If id is set vendor customer is activated
   * TODO: Define global state customer isActive
   */
  async function updateStore({ id, logo }: { id: string | undefined | null; logo: File | null }) {
    // update store data
    if (id) {
      const updateResult = await VendorCustomerProvider.instance.updateStore(id, currentStore.value!.id, new CreateStoreDetailDto(currentStore.value!.storeDetail));
      if (updateResult.success) {
        const refreshedStore = updateResult.value;
        _updateStore(refreshedStore);
      }
    }
    else {
      await useTypedFetch("/storeSpecificService/put", {
        body: new StoreDetailDto(currentStore.value!.storeDetail),
        storeId: currentStore.value!.id,
      });
    }
    // update logo/company image
    if (logo) {
      // use clientside service

      await storeIconUpload({ storeId: currentStore.value!.id, file: logo });
    }
    else if (!logo && !currentStore.value!.storeDetail.iconResource) {
      await storeIconDelete({ storeId: currentStore.value!.id });
    }

    if (!id) {
      const refreshedStore = await useTypedFetch<AnybillResult<StoreDto>>("/storeSpecificService/get", { storeId: currentStore.value!.id });
      if (refreshedStore.success)
        _updateStore(refreshedStore.value);
    }

    storeCreationLogo.value = null;
    currentStore.value = null;
  }

  async function removeStore({ store, id }: { store: StoreDto; id: string | undefined | null }) {
    let res: any;
    const storeId = store.id;
    if (id)
      res = await useTypedFetch("/vendorcustomerSpecificStoreService/delete", { body: store, vendorCustomerId: id });

    else
      res = await useTypedFetch("/storeService/delete", { storeId });

    _removeStore(store);
    _decreaseStoreCount();
  }

  async function _uploadCompanyLogo(storeId: string, image: File) {
    const res = await storeIconUpload({ storeId, file: image });
    if (!res.success)
      AnybillLogger.instance.error("Something went wrong when uploading companyimage", new Error(res.errorMessage ?? ""));
  }

  function _updateStore(store: StoreDto) {
    const clone = ld.cloneDeep(stores.value);
    if (!clone)
      return;
    const toReplaceInd = clone!.findIndex(c => c.id === store.id);
    clone![toReplaceInd] = store;
    stores.value = clone;
  }

  function _removeStore(store: StoreDto) {
    if (!stores.value)
      return;
    stores.value = stores.value!.filter(c => c.id !== store.id);
  }

  function _decreaseStoreCount() {
    totalStoreCount.value--;
  }

  // Todo: create computed totalStores
  function _summarizeStores(_stores: StoreDto[] | null) {
    if (stores.value === null)
      stores.value = [];

    stores.value = stores.value.concat(_stores ?? []);
  }

  return {
    populate,
    populateAll,
    addStore,
    createStore,
    setSortedStores,
    updateStore,
    removeStore,
    reset,
    initialized,
    storeCreationLogo,
    hasStores,
    stores,
    activeStores,
    unavailable,
    loading,
    // indicates wheter populateAll is running or not
    allLoading,
    totalStoreCount,
    currentStore,
    isEdit,
  };
});
